import React from "react"
import "../components/top.css"
import { Helmet } from "react-helmet"
import { Box, Grid } from "@material-ui/core"
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/footer"
import { ContactHeader } from "../components/ContactHeader"
import { ContactTitle } from "../components/ContactTitle"
import WrapTypo from "../components/WrapTypo"
import CookieConsent from "react-cookie-consent"

const ContactsResult = data => {
  const status = data.location.state?.result || "noStatus"
  React.useEffect(() => {
    if (status === "noStatus") {
      // navigate("/")
    }
  })
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Roboto:wght@400;500;700;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText="閉じる"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#fff",
          background: "#f00",
          fontSize: "13px",
          border: "none",
          fontFamily: "Noto Sans JP,sans-serif",
        }}
        expires={150}
      >
        <p style={{ fontFamily: "Noto Sans JP,sans-serif" }}>
          当ウェブサイトはCookieを使用しており、引き続き当サイトを閲覧される場合、当社の個人情報保護方針に同意したものといたします。
        </p>
        <a
          style={{
            textDecoration: "underline",
            fontFamily: "Noto Sans JP,sans-serif",
          }}
          href="https://www.sunfrt.co.jp/policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            style={{ fontSize: "10px", fontFamily: "Noto Sans JP,sans-serif" }}
          >
            個人情報保護方針
          </span>
        </a>
      </CookieConsent>
      <Box
        style={{
          backgroundColor: "#E8DED3",
        }}
      >
        <ContactHeader />
      </Box>
      <Layout>
        <Grid
          container
          style={{
            minHeight: "500px",
            backgroundColor: "#E8DED3",
          }}
        >
          <Grid item xs={1} sm={3} md={4} />
          <Grid item xs={10} sm={6} md={4}>
            <Box pt={3}>
              <Grid container justify={"center"}>
                <Grid />
                <Grid>
                  <ContactTitle />
                  <Box px={2}>
                    <WrapTypo>
                      <>
                        送信を完了いたしました。
                        <br /> お問合せありがとうございます。
                      </>
                    </WrapTypo>
                  </Box>

                  <Box mt={3}>
                    {status === "faild" && (
                      <>
                        <WrapTypo>
                          申し訳ございません。
                          <br />
                          下記メールアドレスより直接ご連絡ください
                          <br />
                          <a
                            href="mailto:a_yotsuyaadmin@sunfrt.co.jp"
                            style={{ color: "blue" }}
                          >
                            a_yotsuyaadmin@sunfrt.co.jp
                          </a>
                          <br />
                        </WrapTypo>
                      </>
                    )}
                  </Box>
                  <Box mt={3}>
                    <Link
                      to="/"
                      style={{
                        color: "blue",
                        textAlign: "center",
                        textDecoration: "underline black",
                      }}
                    >
                      <WrapTypo>TOPページに戻る→</WrapTypo>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={1} sm={3} md={4} />
        </Grid>
        <Footer isContact />
      </Layout>
    </>
  )
}

export default ContactsResult
